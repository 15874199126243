// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/currency/EUR.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/currency/USD.png", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/currency/JPY.png", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/img/currency/CNY.png", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/img/currency/PND.png", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("/static/img/currency/RUB.png", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("/static/img/currency/bitcoin/bsvfull.svg", import.meta.url);
// Module
var code = `<template>
    <require from="./bitcoin_tx_currency.scss"></require>
    <section id="bitcoin_tx_currency" class="flex-column">
        <div id="header" class="flex-row backdrop-blur">
            <img click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
            <h1 i18n="bank.choose_country1"></h1>
        </div>
        <div class="countries flex-column" class.bind="shaking ? 'shake' : ''">
            <div class="country_card flex-row" click.delegate="next('EUR')">
                <img src="${___HTML_LOADER_IMPORT_1___}">
                <p>Euro</p>
                <div class="radio-button-true" if.bind="selectedCurrency == 'EUR'"
                    class.bind="selectedCurrency == 'EUR' ? 'selected' : ''">
                    <div class="inside"></div>
                </div>
            </div>
            <div class="country_card2 row" click.delegate="next('USD')">
                <img src="${___HTML_LOADER_IMPORT_2___}">
                <p>US Dollars</p>
                <div class="radio-button-true" if.bind="selectedCurrency == 'USD'"
                    class.bind="selectedCurrency == 'USD' ? 'selected' : ''">
                    <div class="inside"></div>
                </div>
            </div>
            <div class="country_card flex-row" click.delegate="next('JPY')">
                <img src="${___HTML_LOADER_IMPORT_3___}">
                <p>Japanese Yen</p>
                <div class="radio-button-true" if.bind="selectedCurrency == 'JPY'"
                    class.bind="selectedCurrency == 'JPY' ? 'selected' : ''">
                    <div class="inside"></div>
                </div>
            </div>
            <div class="country_card2 flex-row" click.delegate="next('CNY')">
                <img src="${___HTML_LOADER_IMPORT_4___}">
                <p>Chinese Yen</p>
                <div class="radio-button-true" if.bind="selectedCurrency == 'CNY'"
                    class.bind="selectedCurrency == 'CNY' ? 'selected' : ''">
                    <div class="inside"></div>
                </div>
            </div>
            <div class="country_card flex-row" click.delegate="next('PND')">
                <img src="${___HTML_LOADER_IMPORT_5___}">
                <p>Pound</p>
                <div class="radio-button-true" if.bind="selectedCurrency == 'PND'"
                    class.bind="selectedCurrency == 'PND' ? 'selected' : ''">
                    <div class="inside"></div>
                </div>
            </div>
            <div class="country_card2 flex-row" click.delegate="next('RUB')">
                <img src="${___HTML_LOADER_IMPORT_6___}">
                <p>Ruble</p>
                <div class="radio-button-true" if.bind="selectedCurrency == 'RUB'"
                    class.bind="selectedCurrency == 'RUB' ? 'selected' : ''">
                    <div class="inside"></div>
                </div>
            </div>
            <div class="country_card flex-row" click.delegate="next('BSV')">
                <img src="${___HTML_LOADER_IMPORT_7___}">
                <p>BSV</p>
                <div class="radio-button-true" if.bind="selectedCurrency == 'BSV'"
                    class.bind="selectedCurrency == 'BSV' ? 'selected' : ''">
                    <div class="inside"></div>
                </div>
            </div>
        </div>
        <div class="flex-grow"></div>
        <div class="error" if.bind='isError'>
            <p>
                \${errorMessage}
            </p>
        </div>
    </section>
</template>`;
// Exports
export default code;