// Module
var code = `<template>
  <require from="./bitcoin_generate_qr_code.scss"></require>
  <require from="../../../components/card-banka-detailled/card-banka-detailled"></require>
  <require from="../../../components/header-standard/header-standard"></require>
  <require from="../../../components/ValueConverters/currency-denomination"></require>
  <require from="../../../components/ValueConverters/currency-formatter/currency-formatter"></require> 
  <require from="../../../components/number-format"></require>
  <section id="bitcoin_generate_qr_code" class="flex-column">
    <header-standard show-back-button.bind="showBackButton" show-message.bind="showMessage" message.bind="message" show-logo.bind="showLogo" show-language.bind="showLanguage"></header-standard>    
    <div class="flex-grow"></div>
   <!--  <div class="canvas">
      <img src="/static/img/background.png" if.bind="!qrcode_canvas">
      <canvas id="qrcode_canvqas" ref="qrcode_canvas" if.bind="qrCodeAmount || qrCodeReference"></canvas>
    </div> -->
    </div>

    <!-- <div class="identification_copy_container flex-row" click.delegate="copy()">
      <p class="identification" if.bind="state.wallets[state.selectedAccountIndex].isBlockchain">\${state.wallets[state.selectedAccountIndex].identification}</p>
      <p class="identification" if.bind="!state.wallets[state.selectedAccountIndex].isBlockchain && !state.wallets[state.selectedAccountIndex].isError">\${state.wallets[state.selectedAccountIndex].identification | ibanConverter}</p>
      <p class="identification" if.bind="!state.wallets[state.selectedAccountIndex].isBlockchain && state.wallets[state.selectedAccountIndex].isError">\${state.wallets[state.selectedAccountIndex].identification}</p>
      <img id="first-svg" class="copy" src="/static/svg/components/copy_icon.svg" ref="copyPaymailButton">
      <img id="second-svg" class="copy_check" src="/static/svg/check.svg" ref="copyPaymailButtonCheck">
    </div> -->

    <!-- <div class="flex-grow"></div> -->
    <form id="payment_form" class="flex-column" 
      class.bind="(isSelectingRecipient || isSelectingSender) ? 'selecting' : ''"
      submit.delegate="gotToQrCodeReceive(\$event)">
  
      <!-- Section du montant (payment_price) -->
      <div id="payment_price" class="flex-row">
        <input disabled.bind="locked" id="qrCodeAmount" type="number" step="0.01" min="0"
              value.bind="qrCodeAmount" placeholder="0" required="required"
              class.bind="isAmountError ? 'error' : ''" autofocus/>
        <p id="currency" class="currency">\${state.wallets[state.selectedAccountIndex].currency}</p>
      </div>

      <!-- Section de la référence (payment_reference) -->
      <div id="payment_reference" class="flex-row">
        <input disabled.bind="locked" ref="input_reference" id="qrCodeReference" type="text" value.bind="qrCodeReference"
              placeholder.bind="getRandomCommunication()" class.bind="isCommunicationError ? 'error' : ''"
              focus.delegate="input_reference.placeholder='no'"
              onkeypress="return /[0-9a-zA-Z- ]/i.test(event.key)"
              input.delegate="sanitizeCommentary(qrCodeReference)">
      </div>

      
      <!-- Section des boutons de confirmation -->
      
    </form>
    <div class="flex-grow"></div>
    <div class="confirm">
      <button class="btn btn-primary" if.bind="qrCodeAmount" 
              click.delegate="goToQrCodeReceive()" i18n="home.next" form="payment_form">Suivant</button>
      <button class="btn btn-primary fakeDisabled" if.bind="!qrCodeAmount" 
              click.delegate="shakeError()" t="home.next"></button>
    </div>
    <div class="spacer-16"></div>
  </section>
</template>
`;
// Exports
export default code;