import { YapilyBankDetailsModel } from './../../../components/models/YapilyModels/YapilyBankDetailsModel';
import { YapilyAccountModel } from './../../../components/models/YapilyModels/YapilyAccountModel';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { YapilyTransactionModel } from 'components/models/YapilyModels/YapilyTransactionModel';
import { YapilyBankModel } from 'components/models/YapilyModels/YapilyBankModel';
import { SingletonService } from 'singleton';
import moment from 'moment';
import { I18N } from 'aurelia-i18n';
import { CategoryScale } from 'chart.js';
import { CategorizationHttpClient } from 'http_clients/CategorizationHttpClient';
import { RecipientsHttpClient } from 'http_clients/RecipientsHttpClient';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { DialogController } from 'aurelia-dialog';

@autoinject
export class TransactionsDetails {
    private accountName: string;
    private accountID;

    private amount: number;
    private currency: string;

    private comment: string;

    private date: string;

    private transactionId: string;
    private categorization: string;
    private bank: YapilyBankModel;
    private account: YapilyAccountModel;
    private transaction: YapilyTransactionModel;
    private bank_counter_party: YapilyBankModel = new YapilyBankModel();
    private account_counter_party: YapilyAccountModel = new YapilyAccountModel();

    constructor(private singleton: SingletonService,
        private i18n: I18N,
        private categorizationHttpClient: CategorizationHttpClient,
        private recipientHttpClient: RecipientsHttpClient,
        private router: Router,
        private dialogController: DialogController) {

        this.transaction = this.singleton.getSelectedTransaction()
        this.account = this.singleton.getCurrentBankAccount()
        this.bank = this.singleton.getCurrentBank();

        this.bank_counter_party = new YapilyBankModel();
        this.account_counter_party = new YapilyAccountModel();
        this.bank_counter_party.institutionDetails = new YapilyBankDetailsModel();
        this.bank_counter_party.institutionDetails.media = [];
        this.bank_counter_party.institutionDetails.media[0] = { source: "ok", type: "logo" };
        this.bank_counter_party.institutionDetails.media[0].source = "/img/eubank.png";
        this.account_counter_party.name = "Unknown";

        // * description & montant
        this.amount = this.transaction.amount;
        this.currency = this.transaction.currency;
        this.comment = this.transaction.description;

        // * compte donneur d'ordre
        this.accountName = this.account.accountNames[0].name;
        const accountID = this.account.accountIdentifications[0];
        this.accountID = accountID.type + " " + accountID.identification;
        console.log("THIS IS TRANSACTIONS" + JSON.stringify(this.transaction))

        // * categorisation
        this.setCategory(this.transaction.id)

        // * la date dans un jolie format bien correspondant à la langue choisit
        this.setLangAndDate(this.transaction.bookingDateTime)
    }

    activate() {
        try {
            console.log(this.transaction.payerDetails.name)
            this.getTransactionPayee(this.transaction.payerDetails.name)
        } catch (e) {
            console.log(e)
        }
        /* this.getTransactionPayee(this.transaction.payeeDetails.accountIdentifications[0].identification) */
    }

    async setLangAndDate(date: Date) {
        moment.locale(await this.i18n.getLocale());
        this.date = moment(date).format("LLLL")
    }

    async setCategory(transactionId: string) {
        const res = await this.categorizationHttpClient.fetch(`/categorization/${transactionId}`)
        if (res.status == 200) {
            const categorization = await res.json()
            this.categorization = categorization.category;
        }
        else {
            // this.i18n.tr("not_categorized")
            this.categorization = "non categorisé"
        }
    }

    async getTransactionPayee(name) {
        try {
            let request = await this.recipientHttpClient.fetch('friends/filtered?name=' + name)
            let is200Ok = await checkResponseStatus(request);
            let response = await is200Ok.json();
            console.log('PAYEE DETAILS' + JSON.stringify(response))

        } catch (e) {
            console.log(e)
        }

    }


    goToCategorization() {
        // * transaction déjà dans le singleton mais bon

        this.router.navigateToRoute("select_categorization")
    }

    goToHome() {
        this.router.navigateToRoute("home")
    }

}
