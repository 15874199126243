// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/xmark.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/EU/eubank.png", import.meta.url);
// Module
var code = `<template>
  <require from="./transactions_details.scss"></require>
  <require from="../../../components/max-length"></require>
  <require from="../../../components/number-format"></require>
  <require from="../../../components/ValueConverters/currency-denomination"></require>  <require from="../../../components/date-format-short"></require>
  <require from="../../../components/ValueConverters/iban-converter"></require>

  <section id="transactions_details" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="dialogController.ok()" src="${___HTML_LOADER_IMPORT_0___}" width="30" height="30">
      <h1 i18n="bank.transactions_details.title"></h1>
    </div>
    <div class="flex-grow"></div>
    <div class="spacer-32"></div>
    <div class="facture flex-column">
      <div class="amount">
        <h2 class.bind="(transaction.amount < 0) ? 'negative' : 'positive'">
          \${transaction.amount >= 0 ? '+' : ''}\${amount | numberFormat:'0,0.00'} \${currency| currencyDenomination}
        </h2>
      </div>
      <div class="spacer-32"></div>
      <div class="category flex-row">
        <p class="type" t="bank.transactions_details.category"></p>
        <div class="flex-grow"></div>
        <p class="detail" i18n="soon">\${categorization}</p>
      </div>
      <div class="commentary flex-row">
        <p class="type" t="bank.transactions_details.commentary">Commentaire</p>
        <div class="flex-grow"></div>
        <p class="detail">\${transaction.description}</p>
        <!--\${transaction.enrichment.cleansedDescription}-->
      </div>
      <div class="date flex-row">
        <p class="type" t="bank.transactions_details.datetime"></p>
        <div class="flex-grow"></div>
        <p class="detail">\${transaction.date | dateFormatShort}</p>
      </div>
      <div class="account flex-row">
        <p class="type">Account</p>
        <div class="flex-grow"></div>
        <p class="detail" if.bind="!transaction.payerDetails.accountIdentifications[0].identification">
          \${transaction.payeeDetails.accountIdentifications[0].identification | ibanConverter |
          maxLength: 34}</p>
        <p class="detail" if.bind="!transaction.payeeDetails.accountIdentifications[0].identification">
          \${transaction.payerDetails.accountIdentifications[1].identification | ibanConverter |
          maxLength: 34}</p>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="content flex-row">
      <img src="${___HTML_LOADER_IMPORT_1___}" />
      <!-- <img src.bind="bank.institutionDetails.media[0].source" alt.bind="bank.institutionDetails.name"> -->
      <!-- <img src.bind="getMyBankImage()"> -->
      <div class="name_iban flex-column">
        <p class="title" if.bind="!transaction.payerDetails.name">\${transaction.payeeDetails.name}</p>
        <p class="title" if.bind="!transaction.payeeDetails.name">\${transaction.payerDetails.name}</p>
        <p class="desc" if.bind="!transaction.payerDetails.accountIdentifications[0].identification">
          \${transaction.payeeDetails.accountIdentifications[0].identification | ibanConverter}</p>
        <p class="desc" if.bind="!transaction.payeeDetails.accountIdentifications[0].identification">
          \${transaction.payerDetails.accountIdentifications[1].identification | ibanConverter}</p>
      </div>
      <div class="flex-grow"></div>
    </div>
    <!-- <card-banka-detailled    
      name.bind="transaction.payeeDetails.name"
      iban.bind="transaction.payeeDetails.accountIdentifications[1].identification"
      picture.bind="bank_counter_party.institutionDetails.media[0].source">
    </card-banka-detailled> -->
    <div class="flex-grow"></div>
  </section>
</template>
`;
// Exports
export default code;