// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/background.png", import.meta.url);
// Module
var code = `<template>
    <require from="./receive.scss"></require>
    <require from="../../../../components/card-banka-detailled/card-banka-detailled"></require>
    <require from="../../../../components/ValueConverters/currency-denomination"></require>
    <require from="../../../../components/number-format"></require>
    <section id="bitcoin_receive" class="flex-column">
      <div id="header" class="flex-row">
        <h1 i18n="bitcoin.payment.receive.title"></h1>
      </div>
      <div class="flex-grow"></div>
      <div class="canvas flex-column">
        <img src="${___HTML_LOADER_IMPORT_0___}" if.bind="!qrcode_canvas">
        <canvas id="qrcode_canvas" ref="qrcode_canvas"></canvas>
      </div>
      <div class="features flex-column">
        <div class="features_icons flex-row">
          <i class="fas fa-share-alt" click.delegate="share()"></i>
          <i class="fas fa-copy" click.delegate="copy()"></i> 
        </div>
      </div>
      <div class="copied flex-column">
        <p show.bind="isCopied" i18n="bank.generate_qr_code_receive_copy">Copié avec succès dans le presse-papier</p>
      </div>
      <div class="flex-grow"></div>
      <button class="btn btn-primary" t="home.finish" click.delegate="goToHome()"></button>
      <div class="spacer-default"></div>
    </section>
  </template>
`;
// Exports
export default code;