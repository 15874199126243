import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { CountryModel } from '../../../components/models/YapilyModels/CountryModel';
import { SingletonService } from 'singleton';
import { I18N } from 'aurelia-i18n';

@autoinject
export class bitcoinTxCurrency {


  private currency;
  private selectedCurrency: string;
  private isError: boolean = false;
  private isCurrencySelected: boolean = false;
  private errorMessage: string;
  private shaking: boolean = false;

    constructor(private router: Router,
      private singleton: SingletonService,
      private i18n: I18N) {
    }

  async next(currency) {
    this.selectedCurrency = currency;
    this.currency = this.selectedCurrency;
    this.singleton.setTxCurrency(this.currency);
    this.confirm();
    this.router.navigateBack()
  }

  confirm() {
    if (!this.selectedCurrency) {
        this.isError = true;
        this.errorMessage = this.i18n.tr('bitcoin_tx_currency_error');
    } else {
        this.router.navigateBack
    }
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
        this.shaking = false;
    }, 200);
  }

  goBack() {
      this.router.navigateBack()
  }
}

