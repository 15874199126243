import { AuthService } from "aurelia-auth";
import { json } from "aurelia-fetch-client";
import { autoinject, computedFrom, inject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { SingletonService } from "singleton";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { DialogController } from "aurelia-dialog";
import * as QRCode from "qrcode";
import { default as env } from "../../../../config/environment.json";
import { I18N } from "aurelia-i18n";
import { User } from "components/models/UserModel";
import { ChooseWallet } from "pages/profile/choose_wallet/choose_wallet";
import { WalletInfo, WalletsManager } from "services/WalletManager.service";
import { P2PWallet } from "bsv-wallet";
import { DialogService } from "aurelia-dialog";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { selectAccountIndexAction } from "services/actions/selectAccountIndexAction";

@connectTo()
@autoinject
export class BitcoinGenerateQrCode {
  private qrcode_canvas;
  private qrCodeAmount;
  private qrCodeReference;
  private displayError: boolean;
  private errorMessage: string;
  private shaking: boolean;
  private me: User;
  private url: string;
  private allWalletsInfo: Array<WalletInfo> = [];
  private address: string;
  private walletName: string;
  private walletAmount: number;
  private walletPaymail: string;
  private sanitizedCommentary: string = "";
  private state: State;
  private copyPaymailButton;
  private copyPaymailButtonCheck;

  showBackButton = true;
  showMessage = true;
  message = this.i18n.tr("bitcoin.payment.receive.generate_qr_code1");
  showLogo = false;
  showLanguage = false;

  constructor(
    private dialogController: DialogController,
    private dialogService: DialogService,
    private router: Router,
    private authService: AuthService,
    private singleton: SingletonService,
    private i18n: I18N,
    private paymailWalletHttpClient: PaymailWalletClient,
    private walletsManager: WalletsManager,
    private store: Store<State>
  ) {
    this.me = this.singleton.getMe();
    window.onkeyup = () => {
      if (this.qrCodeAmount) this.setQRCode();
    };
  }

  bind() {
  }

  setQRCode() {
    this.url =
      env.front_front +
      "/payments?identification=" +
      this.state.wallets[this.state.selectedAccountIndex].identification +
      "&comment=" +
      this.qrCodeReference +
      `&name=${this.state.me.displayName}` +
      `&amount=${this.qrCodeAmount}` +
      `&currency=BSV`;
    QRCode.toCanvas(
      this.qrcode_canvas,
      this.url,
      {
        color: {
          dark: "#dfe0e2",
          light: "#0000",
        },
      },
      (error) => {
        if (error) console.log(error);
      }
    );
  }

  copy() {
    navigator.clipboard.writeText(this.state.wallets[this.state.selectedAccountIndex].identification);
    this.copyPaymailButton.style.display = 'none';
    this.copyPaymailButtonCheck.style.display = 'block';

    setTimeout(() => {
      // Hide the second SVG image and show the first SVG image after 2 seconds
      this.copyPaymailButton.style.display = 'block';
      this.copyPaymailButtonCheck.style.display = 'none';
    }, 2000); // Set the delay in milliseconds (e.g., 2000 for 2 seconds)
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  getRandomCommunication() {
    let randomCommunications = [
      this.i18n.tr("bitcoin.payment.receive.rdmCommunication1"),
      this.i18n.tr("bitcoin.payment.receive.rdmCommunication2"),
      this.i18n.tr("bitcoin.payment.receive.rdmCommunication3"),
      this.i18n.tr("bitcoin.payment.receive.rdmCommunication4"),
      this.i18n.tr("bitcoin.payment.receive.rdmCommunication5"),
      this.i18n.tr("bitcoin.payment.receive.rdmCommunication6"),
    ];
    return randomCommunications[
      Math.floor(Math.random() * randomCommunications.length)
    ];
  }

  goToHome() {
    this.router.navigateToRoute("home");
  }

  goToQrCodeReceive() {
    this.singleton.setQrCodeAmount(this.qrCodeAmount);
    this.singleton.setQrCodeReference(this.qrCodeReference);
    this.router.navigateToRoute("bitcoin_receive");
  }

  goToChooseWallet() {
    this.router.navigateToRoute("choose_wallet");
  }

  sanitizeCommentary(newValue: string) {
    const lowercasedValue = newValue.toLowerCase();
    this.sanitizedCommentary = lowercasedValue.replace(/[^a-z0-9_]/g, "_");
    this.qrCodeReference = this.sanitizedCommentary;
  }
}
