import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { SingletonService } from "singleton";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { User } from "components/models/UserModel";
import { HttpClient } from "aurelia-fetch-client";
import { WalletInfo, WalletsManager } from "services/WalletManager.service";
import { DialogController } from "aurelia-dialog";
import * as QRCode from "qrcode";
import { default as env } from "../../../../../config/environment.json";
import { P2PWallet } from "bsv-wallet";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";
import { connectTo, Store } from "aurelia-store";
import { State } from "state";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";

@connectTo()
@autoinject
export class bitcoinReceive {
  private me: User;
  private copyEnabled: boolean = true;
  private copyBitcoinAddresseEnabled: boolean = true;
  private copyPayMailAddresseEnabled: boolean = true;
  private n: number;
  private allWalletsInfo: Array<WalletInfo> = [];
  private address: string;
  private qrcode_canvas;
  private qrCodeAmount: number;
  private qrCodeReference: string;
  private url: string;
  private walletName: string;
  private walletAmount: number;
  private walletPaymail: string;
  private isCopied: boolean = false;
  private state: State;

  constructor(
    private dialogController: DialogController,
    private authService: AuthService,
    private singleton: SingletonService,
    private paymailWalletHttpClient: PaymailWalletClient,
    private router: Router,
    private walletsManager: WalletsManager,
    private store: Store<State>
  ) {
    this.me = this.singleton.getMe();
    this.qrCodeAmount = this.singleton.getQrCodeAmount();
    this.qrCodeReference = this.singleton.getQrCodeReference();
  }

  bind() {
    this.getWalletAddress();
    this.setQRCode();
  }

  async setQRCode() {
    const paymail = this.state.wallets[this.state.selectedAccountIndex].identification;
    this.url =
      env.front_front +
      "/payments?identification=" +
      paymail +
      `&name=${this.me.displayName}` +
      "&comment=" +
      this.qrCodeReference +
      "&amount=" +
      this.qrCodeAmount + 
      "&currency=BSV";
    QRCode.toCanvas(
      this.qrcode_canvas,
      this.url,
      {
        color: {
          dark: "#0000",
          light: "#dfe0e2",
        },
      },
      (error) => {
        if (error) console.log(error);
      }
    );
  }

  async getWalletAddress() {
    let walletId = await this.getWalletFromOwnerIdAndName();

    this.me = this.state.me;

    let request = await this.paymailWalletHttpClient.fetch(
      `/wallets/${walletId}/address/fresh`
    );
    let is200Ok = await checkResponseStatus(request);
    let response = await is200Ok.json();
    this.address = response.address;
  }

  async getWalletFromOwnerIdAndName() {
    const currentWalletInfo = this.state.wallets[this.state.selectedAccountIndex];

    let request = await this.paymailWalletHttpClient.fetch(
      "/wallets/ownerId/" + currentWalletInfo.name
    );
    let is200Ok = await checkResponseStatus(request);
    let wallet = await is200Ok.json();

    this.updateLocalWalletToNewN(currentWalletInfo, wallet.n);
    this.walletName = currentWalletInfo.name;
    this.walletAmount = currentWalletInfo.balance;
    this.walletPaymail = wallet.identification;
    return currentWalletInfo.identification;
  }

  /**
   * Updates local wallet to the N we got from the backend and saves it
   */
  updateLocalWalletToNewN(currentWalletInfo: IGenericAccount, newN: number) {
    let currentWalletObject: P2PWallet = this.walletsManager.get(
      currentWalletInfo.name
    );
    currentWalletObject.lastUsedIndex = newN + 1;
    this.walletsManager.set(currentWalletInfo.name, currentWalletObject);
  }

  share() {
    let data = { url: this.url };
    let sharePromise = window.navigator.share(data);
  }

  copy() {
    navigator.clipboard.writeText(this.url);
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 1000);
  }

  goToChooseWallet() {
    this.singleton.setIsReturnBack(true);
    this.router.navigateToRoute("choose_wallet", { wallet_type: "bitcoin" });
  }

  goToHome() {
    this.router.navigateToRoute("home");
  }
}
